import {
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';

export interface loginData {
  email: string;
  usePassword: boolean;
  password?: string;
}

export default {
  fields: [
    {
      name: 'email',
      component: componentTypes.TEXT_FIELD,
      type: 'email',
      isRequired: true,
      actions: {
        label: ['translate', 'Votre email'],
        validate: [
          'translateErrors',
          [
            {
              type: validatorTypes.REQUIRED,
              message: 'Vous devez indiquer votre adresse email',
            },
            {
              type: validatorTypes.PATTERN,
              pattern:
                // eslint-disable-next-line no-useless-escape
                /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/gm,
              message: "Cette adresse email n'est pas au bon format",
            },
          ],
        ],
      },
    },
  ],
};
